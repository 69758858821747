import React from "react"
import styled from "styled-components"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { Section, ArrowUp, Container, SectionTitle} from "../../components/SectionTags"
import CtaQuote from "../../components/Icons/CtaQuote"
import CtaPhone from "../../components/Icons/CtaPhone"
import RequestQuote from "../../components/RequestQuote"
import LeftBg from "../../images/choose-left.png"
import PrimaryButton from "../../components/Button/PrimaryButton"
import WhiteOutlineButton from "../../components/Button/WhiteOutlineButton"
import BreakpointUp from "../../components/Media/BreakpointUp"

const CtaExpert = styled.div`
  display: flex;
  flex-wrap:wrap;
  margin:0 -15px;
  align-items:center;
  justify-content:space-between;
`
const CtaExpertLeft = styled.div`
  position:relative;
  width:100%;
  padding:0 15px;
  ${BreakpointUp.md`
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  `}
  ${BreakpointUp.xl`
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  `}
`
const CtaExpertRight = styled.div`
  position:relative;
  width:100%;
  padding:0 15px;
  ${BreakpointUp.md`
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  `}
  ${BreakpointUp.xl`
    flex: 0 0 25%;
    max-width: 25%;
  `}
 
`
const CtaExpertBtn = styled.div`
  margin-bottom:30px;
  a, button{
    display: block;
    width: 100%;
    + a{
      margin-top:15px;
    }
    .btn{
      width:100%;
    }
  }
`
const BackgroundImg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(${(props) => props.bg});
  background-size: cover;
  background-position: center center;
`

const ServiceNav = styled.div`
  margin:0 -10px;
  padding-bottom:30px;
  @media (min-width: 1280px){
    padding-bottom:0;
    
  }
`
const ServiceNavTab = styled.strong`
  position:relative;
  font-family: 'UniNeueBold', sans-serif;
  font-weight: 700;
  display: inline-block;
  cursor: pointer;
  color: ${(props) => props.color};
  font-size: 16px;
  line-height: 28px;  
  padding: 5px 10px;
  border: 1px solid ${(props) => props.color};
  border-radius: 30px;
  margin:5px;
  @media (min-width: 1280px){
    font-size: 18px;
    line-height: 30px;
    border: none;
    border-radius: 0;
    padding:10px 10px 40px;
    margin:0;
  }
  &:hover {
    color: #fdc93a;
  }
  .arrow{
    @media (max-width: 1279px){
      display:none !important;
    }
  }

`
ServiceNavTab.defaultProps = {
  color: "rgba(255, 255, 255, 0.5)",
}


const ServiceCard = styled.div`
  background-color:#fff;
  border-radius: 8px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  height: calc(100% - 30px);
  .gatsby-image-wrapper {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
`
const ServiceCardThumb = styled.div`
  .gatsby-image-wrapper{
    width:100%;
  }
`
const ServiceCardTitle = styled.div`
  padding:20px;
  text-align:center;
`
const MoreLink = styled.div`
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  color: #fab702;
  &:hover {
    cursor: pointer;
  }
`
const ServiceList = styled.div`
  display: flex;
  flex-wrap:wrap;
  margin:0 -15px;
`
const ServiceItem = styled(Link)`
  position:relative;
  width:100%;
  padding:0 15px;
  ${BreakpointUp.sm`
    flex: 0 0 50%;
    max-width: 50%;
  `}
  ${BreakpointUp.md`
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  `}
  ${BreakpointUp.xl`
    flex: 0 0 25%;
    max-width: 25%;
  `}
`

class ServiceSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentService: "Electronic Repair",
      limit: 4,
      isOpenRequest: false,
    }
    this.openRequestQuote = this.openRequestQuote.bind(this)
    this.closeRequestQuote = this.closeRequestQuote.bind(this)
    this.selectService = this.selectService.bind(this)
    this.setLimit = this.setLimit.bind(this)
    this.renderMoreLink = this.renderMoreLink.bind(this)
  }
  selectService(data) {
    this.setState({
      currentService: data,
      limit: 4,
    })
  }
  openRequestQuote() {
    this.setState({
      isOpenRequest: true,
    })
    //document.body.style.height = "100vh"
    document.querySelector("body").classList.add("modal-open")
  }
  closeRequestQuote() {
    this.setState({
      isOpenRequest: false,
    })
    //document.body.style.height = "unset"
    document.querySelector("body").classList.remove("modal-open")
  }
  setLimit() {
    const { data } = this.props
    const { currentService } = this.state
    const currentSubSerivces = []
    data.edges.forEach(function (element, index) {
      if (element.node.service.serviceName === currentService) {
        currentSubSerivces.push(element)
      }
    })
    this.setState({
      limit: currentSubSerivces.length,
    })
  }
  renderMoreLink(data) {
    if (data > 0) {
      return (
        <MoreLink onClick={() => this.setLimit()}>
          +{data} more repair service
        </MoreLink>
      )
    }
  }
  render() {
    const { title, services, data } = this.props
    const { currentService, limit, isOpenRequest } = this.state
    const currentSubSerivces = []
    data.edges.forEach(function (element) {
      if (element.node.service.serviceName === currentService) {
        currentSubSerivces.push(element)
      }
    })
    return (
      <>
        <RequestQuote
          display={isOpenRequest ? "block" : "none"}
          onClose={this.closeRequestQuote}
        />
        <Section xpt="80px" mpt="60px" pt="30px" pb="0" bgColor="rgba(0, 89, 178, 0.9)">
          <BackgroundImg bg={LeftBg} />
          <Container>
            <CtaExpert>
              <CtaExpertLeft>
                <SectionTitle color="#fff" mb="30px">{title}</SectionTitle>
              </CtaExpertLeft>
              <CtaExpertRight>
                <CtaExpertBtn>
                  <button onClick={this.openRequestQuote}>
                    <WhiteOutlineButton className="btn-block" size="lg" textAfter="Request a Quote" icon={<CtaQuote />}></WhiteOutlineButton>
                  </button>
                  <a href="tel:8663861001">
                    <PrimaryButton className="btn-block"  size="lg" textAfter="Ask with Expert" icon={<CtaPhone />}></PrimaryButton>
                  </a>
                </CtaExpertBtn>
              </CtaExpertRight>
            </CtaExpert>
            <ServiceNav>
              {services.edges.map((item, i) => {
                return (
                  <ServiceNavTab
                    key={i}
                    color={
                      currentService === item.node.serviceName
                        ? "#FDC93A"
                        : "rgba(255, 255,255, 0.5)"
                    }
                    onClick={() => this.selectService(item.node.serviceName)}
                  >
                    {item.node.serviceName}
                    <ArrowUp className="arrow"
                      display={
                        currentService === item.node.serviceName
                          ? "block"
                          : "none"
                      }
                    />
                  </ServiceNavTab>
                )
              })}
            </ServiceNav>
          </Container>
        </Section>
        <Section xpt="80px" xpb="0" mpt="60px" mpb="0" pt="40px" pb="0">
          <Container>
            <ServiceList>
              {currentSubSerivces.map((item, i) => {
                if (i < limit) {
                  return (
                    <ServiceItem to={`/${item.node.url}`}>
                      <ServiceCard key={i}>
                        <ServiceCardThumb>
                          <GatsbyImage
                            image={getImage(item.node.thumbnail)}
                            alt="AES"
                          />
                        </ServiceCardThumb>  
                        <ServiceCardTitle>
                          <strong className="h4">{item.node.name}</strong>
                        </ServiceCardTitle>
                        </ServiceCard>
                    </ServiceItem>
                  )
                }
                return <></>
              })}
            </ServiceList>
            {this.renderMoreLink(currentSubSerivces.length - limit)}
          </Container>
        </Section>
      </>
    )
  }
}

export default ServiceSection
