import React from "react"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/Header"
import IndustryHeroBg from "../components/Image/IndustryHeroBg"
import CallToAction from "../components/CallToAction"
import EmergencyEstimateCTA from "../components/CallToAction/EmergencyEstimateCTA"
import {
  Section,
  BreadCrumb,
  Background,
  MarkdownContent,
  SectionPageTitle,
  Container,
  Flex,
  FlexTwoCol,
} from "../components/SectionTags"
import LatestArticleVideo from "../components/LatestArticleVideo"
import Process from "../components/Process"
import Content from "../components/Content"

import ServiceSection from "../sections/industry/ServiceSection"
import MoreIndustries from "../sections/industry/MoreIndustries"
import RequestQuote from "../components/RequestQuote"

class IndustryTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpenRequest: false,
    }
    this.openRequestQuote = this.openRequestQuote.bind(this)
    this.closeRequestQuote = this.closeRequestQuote.bind(this)
  }
  openRequestQuote() {
    this.setState({
      isOpenRequest: true,
    })
    //document.body.style.height = "100vh"
    document.querySelector("body").classList.add("modal-open")
  }
  closeRequestQuote() {
    this.setState({
      isOpenRequest: false,
    })
    //document.body.style.height = "unset"
    document.querySelector("body").classList.remove("modal-open")
  }
  render() {
    const { isOpenRequest } = this.state
    const contentData = this.props.data.contentfulIndustry
    const serviceSectionTitle = contentData.secondSectionTitle
    const serviceData = this.props.data.allContentfulService
    const serviceSubData = this.props.data.allContentfulServiceSubRepair
    const processData = contentData.processSection
    const industrySectionTitle = contentData.moreIndustrySectionTitle
    const allIndustries = this.props.data.allContentfulIndustry
    const ctaData = contentData.cta
    const htmlData = contentData.content
    const contentVideo = contentData.contentVideoUrl
    const emergencyCtaData = contentData.emergencyCta
    const estiamteCtaData = contentData.estiamteCta
    const articles = this.props.data.allContentfulArticle
    const videos = this.props.data.allContentfulVideo
    return (
      <Layout>
        <Seo title={contentData.metaTitle} description={contentData.metaDescription} />
        <div className="sticky-wrapper sticky">
          <Header />
        </div>
        <RequestQuote
          display={isOpenRequest ? "block" : "none"}
          onClose={this.closeRequestQuote}
        />
        <Section bgColor="#EDF8FF" xpt="120px" xpb="60px" mpt="120px" mpb="60px" pt="90px" pb="30px">
          <Container>
            <BreadCrumb>
              <Link to="/"><span>Home</span></Link>
              <span>Industries We Serve</span> {contentData.name}
            </BreadCrumb>

            <Flex alignItem="center" justifyContent="space-between">
              <FlexTwoCol>
                <SectionPageTitle>{contentData.name}</SectionPageTitle>
                <MarkdownContent
                  dangerouslySetInnerHTML={{
                    __html: contentData.description.childMarkdownRemark.html,
                  }}
                />
              </FlexTwoCol>
              <FlexTwoCol>
                <GatsbyImage image={getImage(contentData.image)} alt={contentData.name} />
              </FlexTwoCol>
            </Flex>
          </Container>
          <Background bg="#EDF8FF" maxHeight="100%">
            <IndustryHeroBg />
          </Background>
        </Section>
        <ServiceSection
          title={serviceSectionTitle}
          services={serviceData}
          data={serviceSubData}
        />
        <div style={{ position: "relative" }}>
          <Process data={processData}  openRequest={this.openRequestQuote}/>
          <Background right="0"><IndustryHeroBg /></Background>
        </div>
        <MoreIndustries
          title={industrySectionTitle}
          data={allIndustries}
          current={contentData.name}
        />
        <CallToAction data={ctaData} />
        <Content data={htmlData} video={contentVideo} />
        <LatestArticleVideo
          title={contentData.latestSectionTitle}
          articles={articles}
          videos={videos}
        />
        <EmergencyEstimateCTA
          leftData={emergencyCtaData}
          rightData={estiamteCtaData}
        />
      </Layout>
    )
  }
}

export default IndustryTemplate

export const pageQuery = graphql`
  query industryQuery($id: String!) {
    contentfulIndustry(id: { eq: $id }) {
      metaTitle
      metaDescription {
        metaDescription
      }
      name
      image {
        gatsbyImageData(quality: 100)
      }
      description {
        childMarkdownRemark {
          html
        }
      }
      secondSectionTitle
      processSection {
        title
        secondTitle
        features {
          process
          description
        }
      }
      moreIndustrySectionTitle
      cta {
        label
        title
        linkText
      }
      content {
        childMarkdownRemark {
          html
        }
      }
      contentVideoUrl
      estiamteCta {
        title
        linkText
        image {
          gatsbyImageData(quality: 100)
        }
      }
      emergencyCta {
        title
        linkText
        image {
          gatsbyImageData(quality: 100)
        }
      }
      latestSectionTitle
    }
    allContentfulIndustry(sort: { fields: order }) {
      edges {
        node {
          url
          name
          thumbnail {
            gatsbyImageData(quality: 100)
          }
          description {
            childMarkdownRemark {
              excerpt(pruneLength: 250)
            }
          }
          image {
            gatsbyImageData(quality: 100)
          }
          features {
            content
          }
        }
      }
    }
    allContentfulService(sort: { fields: order }) {
      edges {
        node {
          serviceName
        }
      }
    }
    allContentfulServiceSubRepair {
      edges {
        node {
          url
          name
          service {
            serviceName
          }
          thumbnail {
            gatsbyImageData(quality: 100)
          }
        }
      }
    }
    allContentfulArticle(sort: { fields: postDate, order: DESC }, limit: 1) {
      edges {
        node {
          url
          title
          thumbnail {
            gatsbyImageData(quality: 100)
          }
          authorName
          authorRole
          authorImage {
            gatsbyImageData(quality: 100)
          }
          postDate(fromNow: true)
        }
      }
    }
    allContentfulVideo {
      edges {
        node {
          title
          videoUrl
        }
      }
    }
  }
`